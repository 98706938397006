import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is WordPress?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "WordPress is a CMS (content management system) software that allows you to edit and manage the images and text on your website. It is open-sourced and community-developed software that is free for anyone to build their websites."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Do You Use And/Or Buy Pre-Made WordPress Themes?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A Codestaff, we design and code each website from scratch."
        }
    }
}

const Wordpress = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Wordpress Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Wordpress Developers. Top
                                    companies and start-ups choose Codestaff professional Wordpress
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Wordpress Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE WORDPRESS DEVELOPERS' banner='/wordpress.png' bannerAlt='wordpress banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Wordpress